module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/opt/build/repo/website/node_modules/smooth-doc/src/layouts/default.js","docs":"/opt/build/repo/website/node_modules/smooth-doc/src/layouts/docs.js"},"gatsbyRemarkPlugins":[{"resolve":"/opt/build/repo/website/node_modules/smooth-doc/src/plugins/gatsby-remark-autolink-headers/index.js"}]},
    },{
      plugin: require('../node_modules/smooth-doc/src/plugins/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Loadable Components","short_name":"loadable-components","start_url":"/","background_color":"#bd4932","theme_color":"#bd4932","display":"minimal-ui","icon":"src/images/logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"23e8db798737956abd14222d0bf41379"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-154156493-1"},
    },{
      plugin: require('../node_modules/smooth-doc/gatsby-browser.js'),
      options: {"plugins":[],"name":"Loadable Components","slug":"loadable-components","author":"Greg Bergé","description":"The recommended Code Splitting library for React.","siteUrl":"https://loadable-components.com","github":"https://github.com/gregberge/loadable-components","menu":["Introduction","Guides","API"],"nav":[{"title":"Docs","url":"/docs/getting-started/"}],"carbonAdUrl":"//cdn.carbonads.com/carbon.js?serve=CE7I5K3U&placement=loadable-componentscom","googleAnalytics":"UA-154156493-1","algoliaDocSearch":{"apiKey":"e6a731577a7b94aefdbb1fb7dcc71e68","indexName":"smooth-code-loadable-components"}},
    }]
